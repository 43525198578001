import React from 'react'
import styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { FormattedMessage } from 'react-intl';
import parse from 'html-react-parser'
import CaseSection from './CaseSection'
import ArticleSection from './ArticleSection'

import { fonts, colors, media } from '../../../lib/style-utils'
import Section from '../../../styles/Section'
import GlobalTextStyles from '../../General/Text/GlobalTextStyles'
import RevealOpacity from '../../Animation/RevealOpacity'
import RevealFadeTop from '../../Animation/RevealFadeTop'
import ServicesHorizontalScrollView from './ServicesHorizontalScrollView'
import ServicesListView from './ServicesListView'

const MainContainer = styled.div`
    width: 100%;
`

/* SectionExtended */
const SectionExtendedHeader = styled(Section)`
    position: relative;
    top: 0;
    padding-top: 0; 
    padding-bottom: 0;
    max-width: 1650px;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: center;
    justify-content:space-between;
    h3{
        margin-top: 4rem;
        margin-bottom: 1.5rem !important;
        font-size: 33px;
        line-height: 45px;
    }
    ${media.phablet`
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 2rem;
    `}
    ${media.desktop`
        width: 88%;
        max-width: 1650px;
        margin: 0 auto;
    `}
`

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
     > div {
         display: flex;
         flex-direction:row;
     }
`

const SectionText = styled.div`
    padding: 4rem 0.5rem 0 0;
    display: flex;
    flex-direction: column;
    font-family: ${fonts.Montserrat};
    justify-content: center;
    width: 100%;
    ${media.desktop`
        width: 50%;
        padding: 6rem 2rem 3rem 0;
    `};
`
const TextMain = styled.h1`
    font-weight:800;
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: #FF931E;
    margin-bottom: 0;
    word-break: break-word;
    white-space: pre-wrap;
    ${media.xlDesktop`
        font-size: 4rem;
        line-height: 4rem;
        width: 100%;
    `};
`

const HeaderContent= styled.div`
    display:flex;
    ${media.desktop`
        width: 100%;
    `};
`

const HeaderSquareWrapper = styled.div`
     display: none;
     width: 50%;
     ${media.desktop`
        display: block;
     `};
`

const HeaderSquare = styled.div`
     max-width: 40rem;
     margin-right: 0;
     margin-left: auto;
`

const HeaderSquareInner = styled.div`
    padding-top: 118%;
    position: relative;
`

const HeaderSquareImageWrapper = styled.div`
     position: absolute;
     top: 0;
     left: 0;
     wdth: 100%;
     height: 100%;
`

const HeaderSquareImage = styled.img`
    transform: scale(0.8) translateX(-1rem) translateY(-1rem);
    object-fit: cover; 
    width: 100%;
    height: 100%;
    border-radius: 46% 2%;
`

const HeaderSquareBackImage = styled.div`
    transform: scale(0.8) translateX(1rem) translateY(1rem);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    border-radius: 46% 2%;
    background: ${colors.gradient2};
`

const TextIntro = styled.div`
    padding: 1.5rem 0 0 0;
    margin-bottom: 0 !important;
    ${media.desktop`
        max-width: 100%;
        float: left;
    `}
    ${media.lgDesktop`
        max-width: 100%;
        float: left;
    `}
`

const PrimaryButtonWrap = styled.div`
    width: 100%;
`

const PrimaryButton = styled(AniLink)`
    display: inline-block;
    width: auto;
    margin-top: 2rem;
    text-align: center;
    background-color: white;
    border-radius: 0.2rem;
    border: solid 1px black;
    font-size: 1rem;
    color: ${colors.dark_grey};
    padding: 0.6rem 2rem;
    transition: all 0.3s ease;
    ${media.desktop`
        background: ${colors.white};
        color: ${colors.dark_grey};
        transition: all 0.3s ease;
    `};
    :hover{
        transition: background 0.3s ease;
        border-color: orange;
        color: orange;
    }
`

const HeaderSection = ({data, url, caseData, articleData, location}) => {
        const serviceData = data
        return (
            <MainContainer>
                <HeaderContainer>
                    <SectionExtendedHeader>
                        <SectionText>
                            <RevealOpacity>
                                <TextMain>
                                    {serviceData.workServiceTitle}
                                </TextMain>
                                <TextIntro>
                                    <GlobalTextStyles>
                                        <HeaderContent>{parse(serviceData.WorkServicesText)}</HeaderContent>
                                    </GlobalTextStyles>
                                </TextIntro>
                                <PrimaryButtonWrap> 
                                    <PrimaryButton to={`${url}contact`} cover direction="right" duration={1.2} bg="orange"><FormattedMessage id="servicesCardButton"/></PrimaryButton>
                                 </PrimaryButtonWrap>
                            </RevealOpacity>
                        </SectionText>
                        <HeaderSquareWrapper>
                            <RevealOpacity>
                                <HeaderSquare>
                                    <HeaderSquareInner>
                                        <HeaderSquareImageWrapper>
                                            <HeaderSquareBackImage></HeaderSquareBackImage>
                                            <HeaderSquareImage  src={serviceData.workServiceImageSquare[0] && serviceData.workServiceImageSquare[0].url} />
                                        </HeaderSquareImageWrapper>
                                    </HeaderSquareInner>
                                </HeaderSquare>
                            </RevealOpacity>
                        </HeaderSquareWrapper>
                    </SectionExtendedHeader>
                </HeaderContainer>
                <RevealOpacity>
                    {serviceData.ServicesHorizontalView === true ? <ServicesHorizontalScrollView url={url} data={serviceData.servicesSection}/> : <ServicesListView data={serviceData.servicesSection} outro={serviceData.outro}/>}
                </RevealOpacity>  
                <CaseSection data={caseData} url={url} title={serviceData.workServiceTitle} tags={serviceData.categoriesServices}/>
                <ArticleSection data={articleData} url={url} title={serviceData.workServiceTitle} tags={serviceData.categoriesServices} location={location}/>
            </MainContainer>
        )
}

export default HeaderSection