import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import styled from 'styled-components'
import ArticleCardBox from '../../General/BlogCard/HomeCard'
import { FormattedMessage, useIntl } from 'react-intl';

import Section from '../../../styles/Section'
import { media , colors } from '../../../lib/style-utils'

const MainContainer = styled.div`
    position: relative;
    padding: 3rem 0rem 0rem 0rem;
    position: relative;
    ${media.tablet`
        padding: 5rem 2rem;
        max-width: unset;
    `};
`

const MainContainerSection = styled(Section)`
    padding: unset;
`

const BackgroundShape = styled.div`
    background-color: #F2F2F2;
    height: 20rem;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    right: 0;
    z-index: -1;
`
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin: 2rem 0 0.5rem 0;
    > div {
        margin-bottom: 3rem;
    }
    ${media.tablet`
       flex-direction: unset;
       align-items: unset;
       overflow: hidden !important;
       display: flex;
       width: 100%;
       margin-top: 2rem;
       flex-wrap: wrap;
       justify-content: center;
       > div:nth-child(even) {
           justify-content: center;
           ${media.tablet`
                width: 100%;
                flex: 0 43%;
            `};
            ${media.desktop`
                width: 100%;
                flex: 0 50%;
            `};
       }
       > div:nth-child(odd) {
        justify-content: center;
           margin-top: 10rem;
           ${media.tablet`
                width: 100%;
                flex: 0 43%;
            `};
           ${media.desktop`
                width: 100%;
                flex: 0 50%;
            `};
       }
    `};
    
`

const TextWe = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 800;
    max-width: 20rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    padding-left: 1rem;
    width: 100%;
    color: #000;
    align-items:center;
    text-align: center;

    ${media.phablet`
        
    `};

    ${media.tablet`
        padding-left: unset;
        position: ;
        top: 8rem;
        width: 40%;
        position: absolute;
        align-items:flex-start;
        text-align: left;
        max-width: 100%;
    `};
    ${media.desktop`
        font-size: 2rem;
        line-height: 2.5rem;
    `};

    ${media.midDesktop`
        font-size: 2rem;
        line-height:3rem;
    `};
`
const PrimaryButtonWrap = styled.div`
       width: 100%;
       text-align: center !important;
`
const PrimaryButton = styled(AniLink)`
    text-align: center;
    display: inline-block;
    margin: 0 auto 2rem auto !important;
    margin-right: 0 !important;
    background-color: white;
    border-radius: 0.5rem;
    font-size: 1rem;
    color: ${colors.dark_grey};
    padding: 0.6rem 2rem;
    transition: all 0.3s ease;
    border: 1.2px solid ${colors.dark_grey};
    ${media.tablet`
        margin: 2rem auto !important;
        right: 13vw;
        bottom: 7rem;
        position: absolute;
        background: ${colors.white};
        color: ${colors.dark_grey};
        transition: all 0.3s ease;
        border: 1.2px solid ${colors.dark_grey};
        width: 13rem;
        justify-content: flex-end;
        align-self: flex-end;
    `};
        :hover{
            transition: background 0.3s ease;
            border-color: orange;
            color: orange;
        }
`

const ArticleSection = ({data, url ,title, tags, location}) => {

    const articlesData = data.nodes
    const serviceTag = tags[0].title
    let articlesDataFiltered = []

    // Translations
    const intl = useIntl();
    const slugArtikels = intl.formatMessage({id: 'slugArtikels'});

   return (
    <div>
        { 
            articlesData.map((articleSingle, i) => {
                let articleSingleTags = articleSingle.categoriesServices
                let checkTag = articleSingleTags.find(element => element.title === serviceTag)
                
                if(checkTag){
                    articlesDataFiltered.push(articleSingle)
                }
                return null
            })
        }
        {articlesDataFiltered[0] && 
            <MainContainer>
                <MainContainerSection>
                    <BackgroundShape />
                    <TextWe>
                        <FormattedMessage id="CheckOutArticles" /> {title}
                    </TextWe>
                    <Container>
                        {
                            articlesDataFiltered.map((articleSingle, i) => {
                                if (i < 2){
                                    return <ArticleCardBox key={i} data={articleSingle} location={location}/>
                                }
                                return null
                            })
                        }
                    </Container>
                        <PrimaryButtonWrap>
                        <PrimaryButton to={`${url}${slugArtikels}`} cover direction="right" duration={1.2} bg="orange"><FormattedMessage id="buttonMoreArticles"/></PrimaryButton>
                        </PrimaryButtonWrap>
                    </MainContainerSection>
            </MainContainer>
        }
    </div>
   )
}

export default ArticleSection
