import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl';
import CaseCard from '../../General/CaseCard/CaseCardSmall'

import Section from '../../../styles/Section'
import BackgroundImage from '../../../images/services/background.svg'

import { media , colors } from '../../../lib/style-utils'

const MainContainer = styled.div`
    background: url(${BackgroundImage});
    background-repeat:no-repeat;
    background-size:cover;
    padding: 0 1rem;
`

const SectionExtended = styled(Section)`
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    ${media.tablet`
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    `}
`

const Container = styled.div``

const TextWe = styled.span`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2.6rem;
    line-height: 3rem;
    font-weight: 800;
    margin-top: 2rem;
    padding-left: 0;
    color:white;

    ${media.tablet`
        text-align: left;
        padding-left: unset;
        font-size: 2rem;
        line-height: 2.5rem;
    `};
    ${media.desktop`
        font-size: 2.3rem;
        line-height: 3rem;
    `};

    ${media.midDesktop`
        font-size: 2.5rem;
        line-height: 3rem;
    `};
`
const PrimaryButtonWrap = styled.div`
    width: 100%;
    display:flex;
    justify-content: center;
    margin-top: 1rem;
    ${media.tablet`
        justify-content: flex-end;
        margin-top: 2rem;
    `};
`
const PrimaryButton = styled(AniLink)`
    display: flex;
    margin-right: 0 !important;
    background-color: white;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    color: ${colors.dark_grey};
    padding: 0.6rem 2rem;
    transition: all 0.3s ease;
    ${media.tablet`
        background: ${colors.white};
        color: ${colors.dark_grey};
        transition: all 0.3s ease;
        width: auto;
        justify-content: flex-end;
        align-self: flex-end;
    `};
    :hover{
        transition: background 0.3s ease;
        border-color: orange;
        color: orange;
    }
`

const CaseSection = ({data, url, title, tags, location}) => {

    const casesData = data.nodes
    const serviceTags =tags
    const serviceTag = serviceTags[0].title

    let caseDataFiltered = []

    return (
        <div>
            {
                casesData.map((caseSingle, i) => {
                    let caseSingleTags = caseSingle.categoriesServices
                    let checkTag = caseSingleTags.find(element => element.title === serviceTag)
                    
                    if(checkTag){
                        caseDataFiltered.push(caseSingle)
                    }

                    return null
                })
            }
            {caseDataFiltered[0] && 
                <MainContainer>
                    <SectionExtended>
                        <TextWe>
                            {title}
                        </TextWe>
                        <Container className="grid grid-cols-12 gap-0 md:gap-8 lg:gap-12 pt-8">
                            {
                                caseDataFiltered.map((caseSingle, i) => {
                                    if (i < 3){
                                        return <CaseCard key={i} data={caseSingle}/>
                                    }
                                    return null
                                })
                            }
                        </Container>
                            <PrimaryButtonWrap>
                            <PrimaryButton to={`${url}cases`} cover direction="right" duration={1.2} bg="orange"><FormattedMessage id="ServicebuttonMoreCases"/></PrimaryButton>
                            </PrimaryButtonWrap>
                        </SectionExtended>
                </MainContainer>
            }
        </div>
   )
}

export default CaseSection
