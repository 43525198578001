import React from 'react'
import styled from 'styled-components'
import { fonts, colors, media } from '../../../lib/style-utils'
import { FormattedMessage } from 'react-intl';

import Section from '../../../styles/Section'
import RevealFadeTop from '../../Animation/RevealFadeTop'
import ServiceCard from './ServiceCard.js'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import BackgroundGradient from '../../../images/gradient-background.png'

/* Styles Components */
const ServicesContainerWrap = styled.div`
    position: relative;
    padding-bottom: 3rem;
    &::after{
        content: '';
        background-color: #F2F2F2;
        position: absolute;
        z-index: -10;
        height: 60%;
        width: 100%;
        bottom: 0;
        left: 0;
    }
    ${media.desktop`
        padding-bottom: 6rem;
    `}
`
const SectionExtendedHeader = styled(Section)`
    position: relative;
    top: 0;
    padding-top: 0; 
    padding-bottom: 0;
    max-width: 100%;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: center;
    justify-content:space-between;
    h3{
        margin-top: 4rem;
        margin-bottom: 2rem !important;
        font-size: 33px;
        line-height: 45px;
    }
    ${media.phablet`
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 2rem;
    `}
    ${media.desktop`
        width: 88%;
        max-width: 88%;
        margin: 0 auto;
    `}
`

const ServicesContainer = styled.div`
    padding-left: 0;
    padding-bottom: 3rem;
    display: flex;
    align-items: stretch
    justify-content: left;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: visible;
    > div{
        scroll-snap-align: center;
    }
    &::after, &::before{
        content: '';
        position: absolute;
        top: 0;
        width: 6%;
        height: 100%;
        background: linear-gradient(270deg, rgba(242, 242, 242, 0.40) 21.87%, rgba(242, 242, 242, 0) 100%);
        width: 1rem;
        z-index: 9999;
        ${media.phablet`
            width: 2rem;    
        `}
        ${media.desktop`
            width: calc(6% + 2rem);
        `}
    }
    &::after{
        left: 0;
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }
    &::before{
        right: 0;
    }
    ${media.tablet`
        ::-webkit-scrollbar {
            height: 0.5rem;
            color: ${colors.orange};
        }
        ::-webkit-scrollbar-track {
            background: transparent;
        }
        ::-webkit-scrollbar-thumb {
            background: ${colors.orange};
            border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb:hover {
            background: ${colors.orange};
            cursor: pointer !important;
        }
        ::-webkit-scrollbar-track-piece:end {
            background: transparent;
            margin-right: calc(1rem); 
        }
        ::-webkit-scrollbar-track-piece:start {
            background: transparent;
            margin-left: calc(1rem); 
        }
        .scrollbar-background{
            content: '';
            background-color: ${colors.grey};
            position: absolute;
            bottom: 3.15rem;
            height: 0.2rem;
            width: calc(100vw - 2rem);
            left: calc(1rem);
            z-index: -1;
        }
        .scrollbar-background{
            width: calc(100vw - 4rem);
            left: calc(2rem);
        }
        ::-webkit-scrollbar-track-piece:end {
            margin-right: calc(2rem); 
        }
        ::-webkit-scrollbar-track-piece:start {
            margin-left: calc(2rem); 
        }
    `}
    ${media.desktop`
        .scrollbar-background{
            width: calc(100vw - 12vw - 4rem);
            left: calc(6vw + 2rem);
            bottom: 6.15rem;
        }
        ::-webkit-scrollbar-track-piece:end {
            margin-right: calc(6vw + 2rem); 
        }
        ::-webkit-scrollbar-track-piece:start {
            margin-left: calc(6vw + 2rem); 
        }
    `}
`

const Spacer = styled.div`
    display: inline-block;
    background-color: #000;
    height: 10px;
    width: 1rem;
    opacity: 0;
    ${media.phablet`
        width: 2rem;    
    `}
    ${media.desktop`
        min-width: calc(6% + 2rem);
    `}
`

const ServiceExtraCard = styled.div`
    background-image: url(${BackgroundGradient});
    background-size: cover;
    min-width: calc(100% - 4rem);
    display: flex;
    flex-flow: column;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 0 !important;;
    box-shadow: 7px 9px 17px rgba(0, 0, 0, 0.1);
    margin-left: 2rem;
    p{
        text-align: center;
        font-weight: bold;
        font-size: 34px;
        line-height: 45px;
        font-weight: 700 !important;
        max-width: 20rem;
        margin-bottom: 2rem;
        color: ${colors.white};
    }
    ${media.phablet`
        min-width: calc(50% - 4rem);
        p{
            max-width: 15rem;
        }
    `}
    ${media.desktop`
        min-width: 22rem;
    `}
    ${media.lgDesktop`
        min-width: 25rem;
    `}
`

const PrimaryButtonWrap = styled.div`
    width: 100%;
    display:flex;
    justify-content:flex-start;
    align-self: flex-end;
    order: 2;
    ${media.tablet`
        order: 1;
        margin-top:unset;
    `};
`
const PrimaryButton = styled(AniLink)`
    text-align: center;
    display: flex;
    margin: 0 auto;
    background: ${colors.white};
    border: 1.5px solid ${colors.black};
    box-sizing: border-box;
    border-radius: 33px;
    color: ${colors.dark_grey};
    padding: 0.6rem 2rem;
    transition: all 0.3s ease;
    ${media.tablet`
        background: ${colors.white};
        color: ${colors.dark_grey};
        transition: all 0.3s ease;
        width: 12rem;
        justify-content: center;
    `};
    :hover{
        transition: background 0.3s ease;
        background: ${colors.light_grey};;
    }
`

const ServicesHorizontalScrollView = (data, url) => {
        console.log(data.url);
        const servicesData = data.data;
        return (
            <RevealFadeTop>
                <ServicesContainerWrap>
                    <SectionExtendedHeader><h3><FormattedMessage id="OurServices"/></h3></SectionExtendedHeader>
                    <ServicesContainer>
                        <div className="scrollbar-background"></div>
                        <Spacer>test</Spacer>
                        {servicesData.map((service, i) => {
                            return <ServiceCard key={i} data={service} url={data.url}/>;
                        })}
                        <ServiceExtraCard>
                            <p><FormattedMessage id="ServicesConvinced"/></p>
                            <PrimaryButtonWrap> 
                                <PrimaryButton to={`${data.url}contact`} cover direction="right" duration={1.2} bg="orange"><FormattedMessage id="servicesCardExtraButton"/></PrimaryButton>
                            </PrimaryButtonWrap>
                        </ServiceExtraCard>
                        <Spacer>test</Spacer>
                    </ServicesContainer>
                </ServicesContainerWrap>
            </RevealFadeTop>
        )
}

export default ServicesHorizontalScrollView