import React from "react"
import { graphql } from "gatsby"
import { getCurrentLangKey } from 'ptz-i18n';
import styled from 'styled-components'

import Layout from "../components/layout"
import DetailSection from '../components/Services/DetailSection/DetailSection'
import SEO from "../components/seo"

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
`

const Service = (data, location) => {

    const url = data.location.pathname;
    const { langs, defaultLangKey } = data.data.site.siteMetadata.languages;

    let langKey = getCurrentLangKey(langs, defaultLangKey, url);
    let homeLink = `/${langKey}/`

    // SEO
    let seo = {
      title: data.data.service.seo.title,
      description: data.data.service.seo.description,
      lang: data.data.service.language,
      image: data.data.service.seo.social.facebook.image && data.data.service.seo.social.facebook.image.url,
      imageTwitter: data.data.service.seo.social.twitter.image && data.data.service.seo.social.twitter.image.url,
      robots: data.data.service.seo.advanced.robots && data.data.service.seo.advanced.robots
    }

    // Collect data
    const detail = data.data.service
    const caseData = data.data.case
    const articleData = data.data.article

    // 1. Language switcher - Create slugs object for language switcher
    const slugs = {
      slugNl: '',
      slugEn: '',
    }
    
    // 2. Language switcher - Build slugs depending on language
    if (articleData.language == "en"){
      slugs.slugNl = 'diensten/'+data.data.serviceTranslation.slug
      slugs.slugEn = 'services/'+data.data.service.slug
    } else {
      slugs.slugNl = 'diensten/'+data.data.service.slug
      slugs.slugEn = 'services/'+data.data.serviceTranslation.slug
    }

    return(
        <Layout form={true} data={data.data.site} location={data.location} slugs={slugs}>
        <SEO {...seo} />
        <Container>
          <DetailSection data={detail} caseData={caseData} articleData={articleData} ar url={homeLink} location={data.location}/>
        </Container>
      </Layout>
    )
}
  
export default Service

export const query = graphql`
  query ($id: ID!, $siteId:Int, $siteTransId:Int) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    case:allCraftCaseCaseEntry(filter: {siteId: {eq: $siteId}}) {
      nodes {
        id
        uri
        slug
        title
        language
        casesubtitle
        categoriesServices{
          title
        }
        casetext1
        casecardimg {
          url
        }
      }
    }
    article:allCraftArticleArticleEntry(filter: {siteId: {eq: $siteId}}) {
      nodes {
        id
        title
        blogintro
        blogtext1
        slug
        blogreadtime
        dateCreated
        language
        postDate
        author {
          name
        }
        blogcoverimg {
          ... on craft_superkraft_Asset {
            id
            url
          }
        }
        categoriesServices{
          title
        }
      }
    }
    service:craftServicesServicesEntry(remoteId: {eq: $id}, siteId: {eq: $siteId}) {
      ServicesHorizontalView
      servicesSection {
        ... on craft_servicesSection_service_BlockType {
          servicetitle
          serviceparagraph
          serviceimage {
            url
          }
        }
      }
      seo {
        title
        description
        social {
          facebook {
            image {
              url
            }
          }
          twitter {
            image {
              url
            }
          }
        }
        advanced{
          robots
        }
      }
      categoriesServices{
        title
      }
      id
      slug
      language
      workServiceIntro
      WorkServicesText
      workServiceTitle
      workServiceImageSquare {
        url
      }
      outro
    }
    serviceTranslation:craftServicesServicesEntry(remoteId: {eq: $id}, siteId: {eq: $siteTransId}) {
      slug
    }
  }
`
