import React from 'react'
import styled from 'styled-components'
import { media } from '../../lib/style-utils'

/* this styled component can be extended in the component */
const RevealContainer = styled.div`
    [data-aos="reveal_opacity"]{
        opacity: 0;
        
    }
    [data-aos="reveal_opacity"].aos-animate{
        opacity: 1;
        margin-top: 0;
        transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        transition-duration: 1.5s;
        transition-delay: 0.4s;
    }
    ${media.desktop`
        [data-aos="reveal_opacity"]{
            opacity: 0;
            margin-top: 4rem;
        }
    `}
`

const RevealFadeTop = ({children}) => {

        return (
            <RevealContainer className="reveal_container">
                <div 
                    className="reveal_container"
                    data-aos="reveal_opacity"
                    data-aos-offset="20"
                >
                    {children}
                </div>
            </RevealContainer>
        )
}

export default RevealFadeTop