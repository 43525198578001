import React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'

import { fonts, colors, media } from '../../../lib/style-utils'
import { FormattedMessage } from 'react-intl';
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import GlobalTextStyles from '../../General/Text/GlobalTextStyles'

/* Styles Components */
const ServiceWrap = styled.div`
    background-color: #FFF;
    min-width: calc(100% - 4rem);
    display: flex;
    flex-flow: column;
    position: relative;
    padding: 0 !important;;
    box-shadow: 7px 9px 17px rgba(0, 0, 0, 0.1);
    margin-left: 2rem;
    h2{
        font-size: 18px !important;
        line-height: 22px !important;;
        margin-bottom: 0.6rem;
    }
    p, a{
        font-size: 14px !important;
        line-height: 18px !important;
    }
    &:nth-child(3){
        margin-left: 0;
    }
    ${media.phablet`
        min-width: calc(50% - 4rem);
    `}
    ${media.desktop`
        min-width: 22rem;
    `}
    ${media.lgDesktop`
        min-width: 25rem;
    `}
`

const ReadMoreWrap = styled.div`
    position: absolute;
    bottom: 0.75rem;
    left: -0.75rem;
    width: 100%;
    text-align: right;
`

const ReadMore = styled(AniLink)`
    position: relative;
    color: ${colors.lighter_grey} !important;
    display: inline-block;
    &::after{
        content: '';
        width: 100%;
        height: 1px;
        background-color: ${colors.lighter_grey};
        position: absolute;
        bottom: 1px;
        left: 0;
        transition: ease 0.3s background-color;
    }
    &:hover{
        color: ${colors.orange} !important;
        &::after{
            background-color: ${colors.orange};
            transition: ease 0.3s background-color;
        }
    }
`

const ImageWrapper = styled.div`
    width: 100%;
    padding-top: 60%;
    position: relative;
    border-bottom: solid 0.5rem ${colors.orange};
    .inner{
        position: absolute;top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        height: 100%;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
`

const TextWrapper = styled.div`
    padding: 1rem 0.75rem 3rem 0.75rem;
`

const ServiceCard = (data, url) => {
        const cardData = data.data;
        return (
            <ServiceWrap>
                <ImageWrapper>
                    <div className="inner">
                        {cardData.serviceimage[0] && <img src={cardData.serviceimage[0].url} alt={cardData.servicetitle}/>}
                    </div>
                </ImageWrapper>
                <TextWrapper>
                    <GlobalTextStyles>
                        <h2>{cardData.servicetitle}</h2>
                        <p>{parse(cardData.serviceparagraph)}</p>
                        <ReadMoreWrap>
                            <ReadMore to={`${data.url}contact`} cover direction="right" duration={1.2} bg="#lighter_grey"><FormattedMessage id="servicesCardButton"/></ReadMore>
                        </ReadMoreWrap>
                    </GlobalTextStyles>
                </TextWrapper>
            </ServiceWrap>
        )
}

export default ServiceCard