import React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'

import RevealFadeTop from '../../Animation/RevealFadeTop'
import GlobalTextStyles from '../../General/Text/GlobalTextStyles'
import Section from '../../../styles/Section'
import Service_small from '../../../images/services/shape_service.svg'
import { fonts, colors, media } from '../../../lib/style-utils'

/* Styles Components */
const CasesContainer = styled(Section)`
    padding: 2rem 1rem;
    ${media.desktop`
        padding: 5rem 2rem;
    `};
`

const ServiceBlock = styled.div`
    width: 100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    margin-top: 2rem;
    background-image:url(${Service_small});
    background-repeat: no-repeat;
    background-size:45px;
    ${media.desktop`
        padding-right: 2rem !important;
        background-size:65px;
    `}
`

const ServiceBlockInner = styled.div`
    max-width: 35rem;
`

const ServiceBlockTitle = styled.h2`
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight:800;
    color: ${colors.dark_grey};
    font-family: ${fonts.Montserrat};
    padding-top: 0.7rem;    
    padding-left: 1rem;
    max-width: 45rem;
    ${media.desktop`
        font-size: 2rem;
        padding-top: 1rem;    
        line-height: 2.4rem;
    `}
`
const ServiceBlockP = styled.p`
    font-size: 0.8rem;
    color: ${colors.dark_grey};
    font-family: ${fonts.Montserrat};
    margin-top:1rem;
    max-width: 45rem;
    margin-top: 1.5rem;
    img{
        margin: 2rem 0;
        max-width: 100%;
    }
    ${media.desktop`
        margin-top: 2rem;   
    `}
`

const Outro = styled.div`
    margin-top: 2rem;
`

const ServicesListView = (data) => {
    const serviceData = data.data;
    const servicesOutro = data.outro;
    return (
        <RevealFadeTop>
            <CasesContainer>
                <div className="grid grid-cols-12">
                    {serviceData.map((service, i )=> {
                        if (!service) return null
                        return (
                            <ServiceBlock key={i} className="col-span-12 lg:col-span-6">
                                <ServiceBlockInner>
                                    <ServiceBlockTitle>{service.servicetitle}</ServiceBlockTitle>
                                    <ServiceBlockP><GlobalTextStyles>{parse(service.serviceparagraph)}</GlobalTextStyles></ServiceBlockP>
                                </ServiceBlockInner>
                            </ServiceBlock>
                        )
                    })}
                </div>
                <div className="grid grid-cols-1">
                    {servicesOutro && 
                        <Outro>
                            <GlobalTextStyles>{parse(servicesOutro)}</GlobalTextStyles>
                        </Outro>
                    }
                </div>
            </CasesContainer>
        </RevealFadeTop>
    )
}

export default ServicesListView