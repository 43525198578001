import React from 'react'
import styled from 'styled-components'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import moment from 'moment'

import { easings, fonts, media, truncate , colors } from '../../../lib/style-utils'
import parse from 'html-react-parser'

const Container = styled.div`
    a{
        height: auto !important;
    }
    ${media.desktop`
        img, .overlay{
            transition: ${easings.quickest};
        }
        .overlay{
            opacity: 0.5;
        }
        &:hover{
            img{
              transform: scale(1.05);
              transform-origin: center center;
            }
            .overlay{
                opacity: 0;
            }
        }
    `};
    ${media.lgDesktop`
        &:nth-child(3){
            display: block;
        }
    `}
`

const BlogBox = styled.div`
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    font-family: ${fonts.Montserrat};
    width: 100%;
    border-radius: 20px;
    box-shadow: -1px -2px 32px -9px rgba(0,0,0,0.33);
    height: 100%;
    ${media.phablet`
        width: 100%;
    `};
    ${media.desktop`
        margin-bottom: unset;
        width: 100%;
        padding: unset;
        border-radius: unset;
        box-shadow: 0 2px 15px 0 rgba(140,140,140,0.5);
    `};
    ${media.midDesktop`
        width: 100%;
    `};
    ${media.xlDesktop`
        width: 100%;
    `};
`

const Blog = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    ${media.tablet`
        border-radius: unset;
        background-image:none;
    `};
    > a {
        display:flex;
        flex-direction:column;
        height:100%;
    }
`

const BlogImgContainer2 = styled.div`
    position: relative;
    height: auto;
    object-fit: cover;
    object-position: top;
    overflow: hidden;
    padding-top: 60%;
    ${media.tablet`
        display: flex;
        width: 100%;
        height: auto;
    `};
    ${media.desktop`
        display: flex;
        width: 100%;
    `};
`

const BlogImgContainerInner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const BlogImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    position: relative;
    border-top-left-radius: 1.4rem;
    border-top-right-radius: 1.4rem;
    ${media.desktop`
        border-radius: unset;
    `};
`

const BlogOverlay = styled.div`
    background-image: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.3) 34%, rgba(255,255,255,0.1) 63%, rgba(255,255,255,0) 100%);;
    width: 100%;
    height: 100%;
    color:white;
    top: 0;
    left: 0;
    position: absolute;
`
const BlogContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    height: auto;
    padding-top: 0.8rem;
    width: 100%;
    padding: 1rem;
    ${media.tablet`
        height: auto;
    `};
    ${media.desktop`
        padding: 1rem 1rem 2rem 1rem;;
    `};
`
const BlogHeader = styled.div`
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 1rem;
      ${media.tablet`
        margin-top: unset;
        position: absolute;
        width: calc(100% - 2rem);
        bottom: 1rem;
        left: 1rem;
    `};
`

const BlogIndex = styled.span`
    font-family: ${fonts.Montserrat};
    font-size: 0.7rem;
    font-weight: 700;
    color: ${colors.dark_grey};

    span {
        font-weight: 500;
        opacity: 0.5;
    }
`

const BlogTitle = styled(AniLink)`
    display: flex;
    flex-direction: column;
    font-family: ${fonts.Montserrat};
    font-weight: 800;
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0.5rem;
    color: ${colors.dark_grey};

    ${media.desktop`
        min-height: 2rem;
    `}
`

const BlogParagraph = styled.p`
    display: none;
    ${media.tablet`
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-self: flex-start;
        margin-bottom: 2rem;
        font-size: 0.8rem;
        line-height: 1rem;
        font-weight: 500;
        color: ${colors.dark_grey};
    `};
    ${media.desktop`
        font-size: 0.7rem;
        line-height: 1rem;
        margin-bottom: 1rem;
    `};
    ${media.midDesktop`
        font-size: 0.8rem;
        line-height: 1rem;
    `};
`

const CaseCardSmall = (data) => {
    const cardData = data.data
   return (
    <Container className="col-span-12 md:col-span-6 xl:col-span-4 mb-4 md:mb-0 latest_cases_card">
      <BlogBox>
          <Blog> 
            <AniLink to={`/${cardData.language}/cases/${cardData.slug}`} cover direction="top" duration={1.2} bg="orange">
                <BlogImgContainer2>
                    <BlogImgContainerInner>
                        {cardData.casecardimg[0] && <BlogImg src={cardData.casecardimg[0].url} />}
                        <BlogOverlay className="overlay"/>
                    </BlogImgContainerInner>
                </BlogImgContainer2>
                <BlogContent>
                    <BlogTitle to={`/${cardData.language}/cases/${cardData.slug}`} cover direction="top" duration={1.2} bg="orange">{cardData.title}</BlogTitle>
                    <BlogParagraph>{cardData.casesubtitle && parse(truncate(cardData.casesubtitle, 100))}</BlogParagraph>
                    <BlogHeader>
                    <BlogIndex>{moment(cardData.postDate).format('DD/MM/YY')}</BlogIndex>
                        {cardData.categoriesServices[0] && ''}
                    </BlogHeader>
                </BlogContent>
            </AniLink>
          </Blog>
      </BlogBox>
    </Container>
   )
}

export default CaseCardSmall