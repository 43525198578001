import React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import { FormattedMessage } from 'react-intl';

import AniLink from 'gatsby-plugin-transition-link/AniLink'
import TransitionLink from "gatsby-plugin-transition-link"
import { easings, fonts, media, colors,truncate } from '../../../lib/style-utils'
import Arrow from '../../../images/case/arrow.svg'

import RevealFadeTop from '../../Animation/RevealFadeTop';

const Container = styled.div`
    scroll-snap-align: start;
    flex-shrink: 0;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 2rem !important;
    ${media.phablet`
        margin-right: 20px;
    `};
    ${media.tablet`
        margin-bottom: 1rem !important;
        margin-right: 15px;
        border-radius: unset;
        margin-right: unset;
        display: flex;
        width: 100%;
        flex: 0 50%;
        height: 100%;
        justify-content: center;
    `};
`

const CaseBox = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${fonts.Montserrat};
    width: 18rem;
    ${media.phablet`
        width: 55vw;
    `};
    ${media.tablet`
        padding: 0 1rem;
        width: 80vw;
        height: 100%;
        width: 90%;
        padding: unset;
    `};
    ${media.desktop`
        width: 85%;
    `};
    ${media.midDesktop`
        width: 85%;
    `};
    ${media.xlDesktop`
        width: 80%;
    `};
`

const Case = styled.div`
    width: 100%;
    height: 100%;
    background-size: cover;
    position: relative;
    border-bottom: 12px solid orange;
    border-radius: 11px;
    box-shadow: 0 2px 15px 0 rgba(140,140,140,0.5);
    ${media.tablet`
        padding-top: 100%;
        padding-top: 0%;
        border-radius: unset;
        background-image:none;
        border-bottom: unset;
    `};

    ${media.desktop`
        margin-bottom: 0;
        img, .overlay{
            transition: ${easings.quickest};
        }
        .overlay{
            opacity: 0.5;
        }
        &:hover{
            img{
              transform: scale(1.05);
              transform-origin: center center;
            }
            .overlay{
                opacity: 0;
            }
        }
    `}
`

const CaseImgContainer = styled.div`
        overflow: hidden;
`

const CaseImg = styled.img`
      display: flex;
      width: 100%;
      height: auto;
      position: relative;
    ${media.tablet`
      display: flex;
      width: 100%;
      height: auto;
      position: relative;
    `};

    & :after {
        background-image: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.3) 34%, rgba(255,255,255,0.1) 63%, rgba(255,255,255,0) 100%);
        width: 100%;
        height: 100%;
        top: 0;
        color:white;
        position: absolute;
    }
`
const CaseContent = styled.div`
    display: flex;
    background-color:white;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
`
const CaseHeader = styled.div`
    display: none;
    background-color:transparent;
    ${media.tablet`
      display: block;
      text-align: left;
    `};
`

const CaseTag = styled.span`
    display: inline-block;
    font-family: ${fonts.Montserrat};
    font-size: 0.8rem;
    padding-right: 1rem;
    font-weight: normal;
    color: ${colors.dark_grey};
`

const CaseTitle = styled.h2`
    display: flex;
    flex-direction: column;
    font-family: ${fonts.Montserrat};
    font-weight: 800;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: black;
    ${media.lgDesktop`
        font-size: 1.8rem;
        line-height: 2.2rem;
    `};
`
const CaseParagraph = styled.p`
    display: none;
    ${media.tablet`
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-self: flex-start;
        margin-top: 0.5rem;
        margin-bottom: 2rem;
        color: black;
        font-size: 1rem;
    `};
`
const CaseArrow= styled.img`
    width: 0.5rem;
    padding-top: 0.1rem;
    margin-left: 0.4rem;
    ${media.tablet`
        width: 0.6rem;
        padding-top: 0.2rem;
    `};
`

const CaseDiscover = styled(TransitionLink)`
    display: flex;
    flex-direction: column;
    align-items: space-between;
    font-family: ${fonts.Montserrat};
    font-weight: 500;
    font-size: 1rem;
    color: black;
    width: 100%;
    ${media.tablet`
        font-size: 1.1rem;
    `};
`
const CaseDiscoverLink = styled(TransitionLink)`
    display: flex;
    flex-direction: row;
    align-items: space-between;
    font-family: ${fonts.Montserrat};
    font-weight: 500;
    font-size: 1rem;
    color: black;
    width: 100%;
    ${media.tablet`
        font-size: 1.1rem;
    `};
`

const ArticleCardItem = (data, location) => {
    const cardData = data.data

    // Set language for translated slug
    let slugArticles = '';
    if(cardData.language == "en"){
        slugArticles = 'articles';
    } else {
        slugArticles = 'artikels';
    }

   return (
    <Container>
            <CaseBox className="case_box">
                <RevealFadeTop className="shadow-lg">
                    <CaseHeader>
                        {cardData.categoriesServices[0] &&
                            cardData.categoriesServices.map((tag, i) => {
                                if (!tag) return null

                                return (
                                    <CaseTag key={i}>{tag.title}</CaseTag>
                                )
                            })
                        }
                    </CaseHeader>
                    <Case>
                        <CaseDiscover to={`/${cardData.language}/${slugArticles}/${cardData.slug}`} cover direction="right" duration={1.2} bg="orange" state={{ prevPath: data.location.pathname }}>
                            <CaseImgContainer>
                                {cardData.blogcoverimg[0] && <CaseImg src={cardData.blogcoverimg[0].url} />}
                            </CaseImgContainer>
                            <CaseContent>
                                <CaseTitle className="case_title">{cardData.title}</CaseTitle>
                                <CaseParagraph className="case_paragraph">
                                    {cardData.blogintro && truncate(parse(cardData.blogintro),100)}
                                </CaseParagraph>
                                <CaseDiscoverLink to={`/${cardData.language}/${slugArticles}/${cardData.slug}`} cover direction="right" duration={1.2} bg="orange" state={{ prevPath: data.location.pathname }}><FormattedMessage id="casesCardButton"/> <CaseArrow src={Arrow}/></CaseDiscoverLink>
                            </CaseContent>
                        </CaseDiscover>
                    </Case>
                </RevealFadeTop>
            </CaseBox>
    </Container>
   )
}

export default ArticleCardItem